import React, { useState, useEffect } from 'react';
import './App.css';
import loadingGif from './img/giphy.gif'; // Update this path
import giphy2Gif from './img/giphy-2.gif'; // Make sure the path is correct



function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export default function MyApp() {
  const size = useWindowSize();
  const [buttonPosition, setButtonPosition] = useState({ x: 100, y: 100 });
  const [currentImage, setCurrentImage] = useState(require('./img/kitty_default.png')); // Initialize with the first image
  const [isLoading, setIsLoading] = useState(false); // New state to control loading sequence
  const [isAfterLoading, setAfterLoading] = useState(false); // New state to control loading sequence
  const [showContinueButton, setShowContinueButton] = useState(false);






  function changeImage(index) {
    switch (index) {
      case 1:
        setCurrentImage(require('./img/kitty_default.png'));
        setShowContinueButton(false);
        break;
      case 2:
        setCurrentImage(require('./img/kitty_sunglasses.png'));
        setShowContinueButton(false);
        break;
      case 3:
        setCurrentImage(require('./img/kitty_happy.png'));
        setShowContinueButton(true);
        break;
      default:
        console.log("Invalid index");
    }
  }

  const handleMouseMove = e => {
    const distanceX = Math.abs(e.screenX - buttonPosition.x);
    const distanceY = Math.abs(e.screenY - 100 - buttonPosition.y);

    const minX = 50;
    const maxX = size.width - 150;
    const minY = 50;
    const maxY = size.height - 150;

    const randX = Math.floor(Math.random() * (maxX - minX + 1)) + minX;
    const randY = Math.floor(Math.random() * (maxY - minY + 1)) + minY;

    if (distanceX < 150 && distanceY < 150) {
      setButtonPosition({ x: randX, y: randY });
      changeImage(2)
    }
  };

  // New function to handle "Yes" button click
  const handleYesClick = () => {
    setIsLoading(true); // Start loading sequence
    setTimeout(() => {
      setIsLoading(false);
      setAfterLoading(true); // Change view after loading
    }, 5000); // Adjust the time as needed
  };

  // Conditional rendering based on `isLoading`
  if (isLoading) {
    return (
      <div className="loading-screen" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'pink', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <img src={loadingGif} alt="Loading..." />
        <p>Loading, please wait...</p>
      </div>
    );
  }
  else if (isAfterLoading) {
    return (
      <div className="afterLoading" style={{ backgroundImage: giphy2Gif }}>
        <img id="image" style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)' }} src={require('./img/giphy-2.gif')} width="300px" height="250px" alt="kitty" />
        <p className="heading-loading" style={{position: 'absolute', top: '70%', left: '50%', transform: 'translateX(-50%)', fontWeight:50}}>yeyyyyyyyyyyyyyyy</p>
        <p className="heading-loading" style={{position: 'absolute', top: '75%', left: '50%', transform: 'translateX(-50%)', fontWeight:50}}>thanks for being the one for me</p>
        <p className="heading-loading" style={{position: 'absolute', top: '80%', left: '50%', transform: 'translateX(-50%)', fontWeight:50}}>expect another suprise when arriving in germany</p>

      </div>
    );
    
  }

  return (
    <div>
      <h1>Will you be my valentine?</h1>
      <div className="parent" style={{ height: 50, width: window.innerWidth, backgroundColor: 'pink' }}>
        <div className="child" style={{ height: size.height, width: size.width, backgroundColor: 'pink' }}
          onPointerEnter={e => console.log('onPointerEnter (first child)')}
          onPointerLeave={e => console.log('onPointerLeave (first child)')}
          onPointerMove={e => handleMouseMove(e)}
        >

          <button onClick={() => changeImage(3)} style={{ position: 'absolute', top: '50%', left: '50%' }}>

            <span class="box">
              YES
            </span>

            <div class="star-1">
              <svg height="25" width="25" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.313,88.686c-47.803-47.803-125.213-47.803-173.016,0l-17.087,17.087l-17.087-17.087
    c-47.803-47.803-125.213-47.803-173.016,0c-47.803,47.803-47.803,125.213,0,173.016l190.103,190.103
    c4.88,4.88,11.316,7.322,17.752,7.322c6.435,0,13.871-2.442,18.751-7.322l190.103-190.103
    C475.116,213.899,475.116,136.489,427.313,88.686z" fill="#fd1853"></path>
              </svg>


            </div>
            <div class="star-2">
              <svg height="20" width="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.313,88.686c-47.803-47.803-125.213-47.803-173.016,0l-17.087,17.087l-17.087-17.087
    c-47.803-47.803-125.213-47.803-173.016,0c-47.803,47.803-47.803,125.213,0,173.016l190.103,190.103
    c4.88,4.88,11.316,7.322,17.752,7.322c6.435,0,13.871-2.442,18.751-7.322l190.103-190.103
    C475.116,213.899,475.116,136.489,427.313,88.686z" fill="#fd1853"></path>
              </svg>

            </div>
            <div class="star-3">
              <svg height="9" width="9" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.313,88.686c-47.803-47.803-125.213-47.803-173.016,0l-17.087,17.087l-17.087-17.087
    c-47.803-47.803-125.213-47.803-173.016,0c-47.803,47.803-47.803,125.213,0,173.016l190.103,190.103
    c4.88,4.88,11.316,7.322,17.752,7.322c6.435,0,13.871-2.442,18.751-7.322l190.103-190.103
    C475.116,213.899,475.116,136.489,427.313,88.686z" fill="#fd1853"></path>
              </svg>

            </div>
            <div class="star-4">
              <svg height="10" width="10" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.313,88.686c-47.803-47.803-125.213-47.803-173.016,0l-17.087,17.087l-17.087-17.087
    c-47.803-47.803-125.213-47.803-173.016,0c-47.803,47.803-47.803,125.213,0,173.016l190.103,190.103
    c4.88,4.88,11.316,7.322,17.752,7.322c6.435,0,13.871-2.442,18.751-7.322l190.103-190.103
    C475.116,213.899,475.116,136.489,427.313,88.686z" fill="#fd1853"></path>
              </svg>

            </div>
            <div class="star-5">
              <svg height="20" width="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.313,88.686c-47.803-47.803-125.213-47.803-173.016,0l-17.087,17.087l-17.087-17.087
    c-47.803-47.803-125.213-47.803-173.016,0c-47.803,47.803-47.803,125.213,0,173.016l190.103,190.103
    c4.88,4.88,11.316,7.322,17.752,7.322c6.435,0,13.871-2.442,18.751-7.322l190.103-190.103
    C475.116,213.899,475.116,136.489,427.313,88.686z" fill="#fd1853"></path>
              </svg>

            </div>
            <div class="star-6">
              <svg height="7" width="7" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.313,88.686c-47.803-47.803-125.213-47.803-173.016,0l-17.087,17.087l-17.087-17.087
    c-47.803-47.803-125.213-47.803-173.016,0c-47.803,47.803-47.803,125.213,0,173.016l190.103,190.103
    c4.88,4.88,11.316,7.322,17.752,7.322c6.435,0,13.871-2.442,18.751-7.322l190.103-190.103
    C475.116,213.899,475.116,136.489,427.313,88.686z" fill="#fd1853"></path>
              </svg>

            </div>

          </button>

          <img id="image" style={{ position: 'absolute', top: '60%', left: '48%' }} src={currentImage} width="200" height="200" alt="kitty" />


          <button style={{ position: 'absolute', top: buttonPosition.y, left: buttonPosition.x, transition: 'top 0.5s, left 0.5s' }}>
            <span class="box">
              NO
            </span>

            <div class="star-1">
              <svg height="25" width="25" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.313,88.686c-47.803-47.803-125.213-47.803-173.016,0l-17.087,17.087l-17.087-17.087
    c-47.803-47.803-125.213-47.803-173.016,0c-47.803,47.803-47.803,125.213,0,173.016l190.103,190.103
    c4.88,4.88,11.316,7.322,17.752,7.322c6.435,0,13.871-2.442,18.751-7.322l190.103-190.103
    C475.116,213.899,475.116,136.489,427.313,88.686z" fill="#fd1853"></path>
              </svg>


            </div>
            <div class="star-2">
              <svg height="20" width="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.313,88.686c-47.803-47.803-125.213-47.803-173.016,0l-17.087,17.087l-17.087-17.087
    c-47.803-47.803-125.213-47.803-173.016,0c-47.803,47.803-47.803,125.213,0,173.016l190.103,190.103
    c4.88,4.88,11.316,7.322,17.752,7.322c6.435,0,13.871-2.442,18.751-7.322l190.103-190.103
    C475.116,213.899,475.116,136.489,427.313,88.686z" fill="#fd1853"></path>
              </svg>

            </div>
            <div class="star-3">
              <svg height="9" width="9" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.313,88.686c-47.803-47.803-125.213-47.803-173.016,0l-17.087,17.087l-17.087-17.087
    c-47.803-47.803-125.213-47.803-173.016,0c-47.803,47.803-47.803,125.213,0,173.016l190.103,190.103
    c4.88,4.88,11.316,7.322,17.752,7.322c6.435,0,13.871-2.442,18.751-7.322l190.103-190.103
    C475.116,213.899,475.116,136.489,427.313,88.686z" fill="#fd1853"></path>
              </svg>

            </div>
            <div class="star-4">
              <svg height="10" width="10" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.313,88.686c-47.803-47.803-125.213-47.803-173.016,0l-17.087,17.087l-17.087-17.087
    c-47.803-47.803-125.213-47.803-173.016,0c-47.803,47.803-47.803,125.213,0,173.016l190.103,190.103
    c4.88,4.88,11.316,7.322,17.752,7.322c6.435,0,13.871-2.442,18.751-7.322l190.103-190.103
    C475.116,213.899,475.116,136.489,427.313,88.686z" fill="#fd1853"></path>
              </svg>

            </div>
            <div class="star-5">
              <svg height="20" width="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.313,88.686c-47.803-47.803-125.213-47.803-173.016,0l-17.087,17.087l-17.087-17.087
    c-47.803-47.803-125.213-47.803-173.016,0c-47.803,47.803-47.803,125.213,0,173.016l190.103,190.103
    c4.88,4.88,11.316,7.322,17.752,7.322c6.435,0,13.871-2.442,18.751-7.322l190.103-190.103
    C475.116,213.899,475.116,136.489,427.313,88.686z" fill="#fd1853"></path>
              </svg>

            </div>
            <div class="star-6">
              <svg height="7" width="7" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.313,88.686c-47.803-47.803-125.213-47.803-173.016,0l-17.087,17.087l-17.087-17.087
    c-47.803-47.803-125.213-47.803-173.016,0c-47.803,47.803-47.803,125.213,0,173.016l190.103,190.103
    c4.88,4.88,11.316,7.322,17.752,7.322c6.435,0,13.871-2.442,18.751-7.322l190.103-190.103
    C475.116,213.899,475.116,136.489,427.313,88.686z" fill="#fd1853"></path>
              </svg>

            </div>

          </button>
          {showContinueButton && (
            <button onClick={handleYesClick} className="continue" style={{ position: 'absolute', top: '80%', left: '80%' }}>
              <span>Continue</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 74 74" height="34" width="34">
                <circle strokeWidth="3" stroke="black" r="35.5" cy="37" cx="37"></circle>
                <path fill="black" d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z"></path>
              </svg>
            </button>
          )}

        </div>
      </div>
    </div>
  );
}
